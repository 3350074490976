@keyframes latido {
    0% {
        transform: scale(1, 1);
    }
    50%{
        transform: scale(1.1, 1.1);
    }
   100% {
        transform: scale(1, 1);
    }
} 

@keyframes entrada {
    0% {
        left: -200px;
        opacity: 0;
    }
    100% {
        left: 80px;
        opacity: 1;
    }
}
@keyframes salida {
0% {
    left: 80px;
    opacity: 1;
}
100% {
    left: -200px;
    opacity: 0;
}
}

.heart.active{ 
    width: 100%;
    height: 100%;
    animation:latido 1s infinite;
}

.heart { 
    width: 100%;
    height: 100%;
}

.msg {
    position: absolute;
    top: 20px;
    left: -200px;
    opacity: 0;
    background: #44c554;
    padding-top: 2px;
    padding-inline: 10px;
    color: white;
    border: 2px solid white;
    border-radius: 10px
}
.msg.entrar{
    animation: entrada 1.5s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
}
.msg.salir{
    animation: salida 1.5s;
    animation-delay: 1s;
    animation-fill-mode: backwards;
}