/*------ 16. Breadcrumb style  --------*/

.breadcrumb-content {
  ul {
    li {
      display: inline-block;
      font-size: 15px;
      font-weight: 500;
      color: white;
      margin-right: 18px;
      text-transform: uppercase;
      position: relative;

      &::before {
        position: absolute;
        width: 18px;
        height: 1px;
        background-color: #f8e31d;
        content: "";
        right: -21px;
        top: 12px;
        z-index: 99;
        transform: rotate(115deg);
      }

      &:last-child::before {
        display: none;
      }

      a {
        color: white;

        &:hover {
          color: #0072bb;
        }
      }
    }
  }

  span {
    &>span {
      display: inline-block;
      font-size: 15px;
      font-weight: 500;
      text-transform: uppercase;
      color: white;

      &>a {
        font-weight: 500;
        color: white;

        &:hover {
          color: #0072bb;
        }
      }

      &>span {
        margin-right: 0;
        margin: 0 15px;
      }
    }
  }
}

.breadcrumb-area {
  background-color: #f8e31d;
}